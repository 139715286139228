<template>
	<div></div>
</template>

<script>
import SlackService from '@/services/SlackService';
export default {
	name: 'MicrosoftCallback',
	created() {
		try { 
			// check if code and state are available  
			if (window.location.search.includes("code=")) {
				SlackService.exchangeToken(this.$route.query.code);
			} else {
				this.$router.push({ name: "Logout" });
			}

		} catch(error) {
			console.error(error)
			this.$router.push({ name: "Logout" });
		}
	}
}
</script>