import axios from "axios"
import router from "@/router";
import { getUserDoc } from "@/firebase";
import { doc, setDoc, getDoc, updateDoc } from "@firebase/firestore";
import store from "@/store"

import { slack as oauth } from "@/config/oauth"

import VueCookie from "vue-cookie";

class SlackService {
	timer

	async doc() {
		const userDoc = await getUserDoc()
		if(!userDoc) {
			console.error("Not logged in")
			router.push({ name: "Auth" })
		}
		return doc(userDoc, "integrations", "slack")
	}

	createChannel(name) {
		let body = new URLSearchParams();
		body.append("name", name);
		return this.apiRequest("https://slack.com/api/conversations.create", body);
	}

	inviteUsers(channelId, users) {
		let body = new URLSearchParams();
		body.append("channel", channelId);
		body.append("users", users);
		return this.apiRequest("https://slack.com/api/conversations.invite", body);
	}

	sendMessage(channelId, text) {
		let body = new URLSearchParams();
		body.append("channel", channelId);
		body.append("blocks", JSON.stringify([
			{
				"type": "section",
				"text": {
					"type": "mrkdwn",
					"text": text
				}
			}
		]));
		return this.apiRequest("https://slack.com/api/chat.postMessage", body);
	}

	pinMessage(channelId, messageTs) {
		let body = new URLSearchParams();
		body.append("channel", channelId);
		body.append("timestamp", messageTs);
		return this.apiRequest("https://slack.com/api/pins.add", body);
	}

	apiRequest(url, data) {
		return new Promise((resolve, reject) => {
			this.getToken().then(token => {
				data.append("token", token);
				axios.post(url, data).then(response => {
					console.log(`RESPONSE.DATA.OK ${response.data.ok}`)
					if(!response.data.ok) reject(response)
					else resolve(response)
				}, reject);
			}).catch(error => {
				reject(error)
			});
		});
	}

	async getToken() {
		const token = VueCookie.get("slackToken");
		if(token && await this.verifyToken(token)) return token
		await this.refreshToken();
		return VueCookie.get("slackToken");
	}

	async verifyToken(token) {
		return new Promise((resolve) => {
			const requestOptions = {
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
				body: `token=${token}`
			};
			fetch("https://slack.com/api/auth.test", requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data.ok))
		})
	}

	startLink() {
		let link = "https://slack.com/oauth/v2/authorize"
		link += `?client_id=${oauth.client_id}`
		link += `&user_scope=${oauth.scope}`
		link += `&redirect_uri=${oauth.redirect_uri}`
		link += `&team=${oauth.team}`

		window.location.href = link
	}

	exchangeToken(code) {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
			body:
				"grant_type=authorization_code" +
				`&code=${code}` +
				`&client_id=${oauth.client_id}` +
				`&client_secret=${oauth.secret}` + 
				`&redirect_uri=${oauth.redirect_uri}`,
		};

		fetch("https://slack.com/api/oauth.v2.access", requestOptions)
			.then((response) => response.json())
			.then(async (data) => {
				console.log(data)
				if(!data.ok) return
				VueCookie.set("slackToken", data.authed_user.access_token, { expires: `${data.authed_user.expires_in}s`});
				setDoc(await this.doc(), {
					refreshToken: data.authed_user.refresh_token,
					linked: true
				});

				updateDoc(await getUserDoc(), {
					slackID: data.authed_user.id
				});

				// set timer to refresh token based on expire info
				this.setTokenRefreshTimer(data.authed_user.expires_in);

				// token acquired, send to last route
				router.returnToSaved();
			})
	}

	setTokenRefreshTimer(expires_in) {
		// set timer to autorenew token if possible just before expires
		clearTimeout(this.timer); //clear existing timer
		let secondsToExpire = (expires_in - (5 * 60)); // attempt renew 5min before expiration
		let _this = this // JS is strange, trust me, this is needed
		this.timer = setTimeout(() => _this.refreshToken(), secondsToExpire * 1000);
	}

	async isLinked() {
		return await this.getToken() != undefined;
	}

	async refreshToken() {
		console.log("Refreshing token")
		VueCookie.delete("slackToken")
		try {
			let docSnap = await getDoc(await this.doc())
			if(!docSnap.exists()) {
				store.commit("setError", {msg: "Please link your slack account."});
				return;
			}
			const docData = docSnap.data();
			if(!docData.refreshToken) {
				store.commit("setError", {msg: "Slack account lacks refresh token"});
				return;
			}
			const requestOptions = {
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
				body:
					"grant_type=refresh_token" + 
					`&refresh_token=${docData.refreshToken}` + 
					`&client_id=${oauth.client_id}` +
					`&client_secret=${oauth.secret}` + 
					`&redirect_uri=${oauth.redirect_uri}`
			};

			let response = await fetch("https://slack.com/api/oauth.v2.access", requestOptions);
			let data = await response.json()
			if(!data.ok) {
				store.commit("setError", {
					msg: data.error
				})
				return
			}
			VueCookie.set("slackToken", data.access_token, { expires: `${data.expires_in}s`});
			updateDoc(await this.doc(), {
				refreshToken: data.refresh_token
			});

			// set timer to refresh token based on expire info
			this.setTokenRefreshTimer(data.expires_in);
		} catch (err) {
			store.commit("setError", {
				msg: err,
			});
		}
	}
}

export default new SlackService();