module.exports = {
    microsoft: {
        authorizeEndPoint: process.env.VUE_APP_MSAL_AUTHORIZE_ENDPOINT,
        tokenEndPoint: process.env.VUE_APP_MSAL_TOKEN_ENDPOINT,
        response_type: 'code',
        client_id: process.env.VUE_APP_MSAL_CLIENTID,
        redirect_uri: process.env.VUE_APP_MSAL_REDIRECTURI,
        scope: 'User.Read',
        state: '12344321', 
    },
    slack: {
        client_id: process.env.VUE_APP_SLACK_CLIENTID,
        secret: process.env.VUE_APP_SLACK_CLIENT_SECRET,
        scope: process.env.VUE_APP_SLACK_SCOPE,
        redirect_uri: process.env.VUE_APP_SLACK_REDIRECTURI,
        team: process.env.VUE_APP_SLACK_TEAM_ID
    }
}